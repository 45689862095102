import React from 'react';
import Modal from '../../../components/Modal';
import Quote from '../../../components/Quote';

import img from '../../../images/insights/12-insights.jpg';

const media = {
  secTitle: 'Inclusion Comes at a Cost',
  type: 'image',
  image: {
    src: img,
  },
};

export default () => (
  <Modal closeTo="Learned" media={media}>
    <p className="Title--2 Title--c">
      For many people of color, efforts made toward equity, inclusion, and
      integration have often come with deep costs, both hidden and overt.
    </p>
    <p>
      Many older black residents in Southern communities, while acknowledging
      the new economic opportunities afforded by desegregationist policies, are
      still mourning the loss of black institutions that served as pillars in
      their communities. Others are attuned to the ways black businesses and
      neighborhoods were displaced or destroyed in the name of “urban renewal”
      projects that did not benefit them.
    </p>
    <p>
      For many people of color, inclusion in practice partly means conforming to
      the “white world” — to its institutions and manners of speaking — rather
      than carving out alternative institutions and ways of life.
    </p>
    <p>
      Finally, across the South, faith institutions and community groups are
      filling a critical gap left by the decades-long disinvestment in other
      forms of traditional community-building and advocacy, such as nonprofit
      organizations.
    </p>
    <Quote
      quote="We lost community when they integrated. Had we kept the segregation mentality and integrated education, we’d have been all right, but because they gave up so much for what they thought was integration, the town has been into a deterioration."
      person="Black male, 73"
      title="Tallulah"
    />
    <Quote
      quote="I think as an African American society the things that worked in our community, they took it away from us. Some of our high schools, you know, we had a predominantly black high schools, okay, integration, fine. But these things were working for the community, people were together, now people are just pulling apart and we as a race we pull against each other. So, it’s a hindrance."
      person="Black female, 67"
      title="Richmond"
    />
    <Quote
      quote="We should have kept our businesses, we should have... The reason we wanted integration was to have opportunities. That was the basis for it. Not to just disregard everything that we have worked so hard for, or our parents have worked so hard for."
      person="Black female, 75"
      title="Richmond"
    />
  </Modal>
);
